

import React,{useState,useEffect} from 'react';
import { Col ,Card,CardBody, CardHeader,Row,Modal,ModalBody} from 'react-bootstrap';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { RightSquareTwoTone } from '@ant-design/icons';
import { Skeleton } from 'antd';
import MapContainerData from './MapContainer';
import { Select, Space } from 'antd';

export default function MajorStats({majorStats,label}) {
    const [open,setOpen]=useState(false)
    const [districtList,setdistrictList]=useState([])
     const[details,setDetails]=useState({
        state:"",year:"",crop:""
     })
     const [mapList, setMapList] = useState([]);

     const handleClick=(state,year,season,crop)=>{



     const fetchData = async () => {
        try {
          const response = await fetch(`https://farmconnect.agrani.io/map_stats?repo=${label}&state=${state}&year=${year}&crop=${crop}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          console.log("year",data)
          setdistrictList(data?.district_list)
          setDetails({
             state:state,year:year,crop:crop
          })
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
     setOpen(true)



     }
     const handleClose = () => {
        setdistrictList([])
        setMapList([])
        setDetails({
            state:"",year:"",crop:""
         })
        setOpen(false);
      };
    
      const renderTable = () => {
        return (
          <div className="table-container backgroundColorBeigeHealth">
      <table className="table">
        <thead>
          <tr>
            <th className="th text-gray text-center">State</th>
            <th className="th text-gray text-center">Year</th>
            <th className="th text-gray text-center">Season</th>
            <th className="th text-gray text-center">Crop</th>
            <th className="th text-gray text-center">No of {label.toUpperCase()}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(majorStats).map((state) => {
            return (
              <React.Fragment key={state}>
                <tr>
                  <td
                    className="td text-gray"
                    rowSpan={Object.keys(majorStats[state]).reduce(
                      (acc, year) =>
                        acc +
                        Object.keys(majorStats[state][year]).reduce(
                          (acc2, season) =>
                            acc2 +
                            Object.keys(
                              majorStats[state][year][season]
                            ).length +
                            1,
                          1
                        ),
                      1
                    )}
                  >
                    {state}
                  </td>
                </tr>
                {Object.keys(majorStats[state]).map((year) => {
                  const yearClass = year % 2 === 0 ? 'bluecolor' : 'greenSample'; // Alternate row colors for years
                  return (
                    <React.Fragment key={year}>
                      <tr>
                        <td
                          className={`td ${yearClass}`}
                          rowSpan={Object.keys(majorStats[state][year]).reduce(
                            (acc, season) =>
                              acc +
                              Object.keys(majorStats[state][year][season]).length +
                              1,
                            1
                          )}
                        >
                          {year}
                        </td>
                      </tr>
                      {Object.keys(majorStats[state][year]).map((season) => {
                        return (
                          <React.Fragment key={season}>
                            <tr>
                              <td className="td" rowSpan={Object.keys(majorStats[state][year][season]).length + 1}>
                                {season}
                              </td>
                            </tr>
                            {Object.keys(majorStats[state][year][season]).map((crop) => {
                              return (
                                <tr key={crop}>
                                  <td className="td crop">{crop}</td>
                                  <td className="td amount">{majorStats[state][year][season][crop]} 

                                  <Tooltip title="View on Map">
                                     &nbsp;&nbsp;<RightSquareTwoTone onClick={()=>handleClick(state,year,season,crop)} />
                                  </Tooltip>
                                  </td>
                                  
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
          </div>
        );
      };
      const handleChange = (e) => {
        setMapList([])
        fetchDataMap(e.target.value);
      };
      const fetchDataMap = async (district) => {
        try {
          console.log(`Fetching data for district: ${district}`);
          const response = await fetch(
            `https://farmconnect.agrani.io/map_stats?repo=${label}&state=${details.state}&year=${details.year}&crop=${details.crop}&district=${district}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          console.log("Fetched data:", data);
          setMapList(data?.map_data || []);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(() => {
        if (districtList && districtList.length > 0) {
            fetchDataMap(districtList[0]); // Fetch data for the first district initially
        }
      }, [districtList]);
    
      return (
        <div>
        <Card className='mt-5 cardShadow'>
            <CardBody >
        <div className='bluecolor h6 p-3'>Crop-wise Season-wise Distribution</div>
        {Object.keys(majorStats).length > 0 ? 

          renderTable()
          :<Skeleton
          avatar
          paragraph={{
            rows: 14,
          }}
        />}
          </CardBody>
          {/* <MapContainerData districtList={districtList} details={details} label={label}/> */}

          {/* <Modal
        open={open}
        onCancel={handleClose}
        // width={800}
        footer={[
          <Button key="close" onClick={handleClose}>
            Close
          </Button>
        ]}
      >
        <ModalBody>
        <MapContainerData districtList={districtList} details={details} label={label}/>
        </ModalBody>
      </Modal> */}
        <Modal
        show={open} onHide={handleClose} size="xl"
       
      >
         <Modal.Header closeButton>
                </Modal.Header>
        <ModalBody >
            <label className='mx-3'> Select District</label>
         <select onChange={handleChange} className='selectbox p-2'>
             {districtList?.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select> 
         
        <MapContainerData districtList={districtList} details={details} label={label} mapList={mapList}/>
        </ModalBody>
      </Modal>
     
        </Card>
        </div>
      );
    }