// import React, { useState, useEffect } from "react";
// import {
//   MapContainer,
//   TileLayer,
//   Marker,
//   Popup
// } from "react-leaflet";
// import "leaflet/dist/leaflet.css";

// import L from "leaflet";
// import markerIcon from "leaflet/dist/images/marker-icon.png";
// import markerShadow from "leaflet/dist/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: markerIcon,
//   shadowUrl: markerShadow
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const MapContainerData = ({ districtList, details, label }) => {
//     const [center, setCenter] = useState([27.947147057947525, 79.36523437500001]);
//     const [mapList, setMapList] = useState([]);
  
//     const fetchData = async (district) => {
//       try {
//         console.log(`Fetching data for district: ${district}`);
//         const response = await fetch(
//           `http://13.233.184.224/map_stats?repo=${label}&state=${details.state}&year=${details.year}&crop=${details.crop}&district=${district}`
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const data = await response.json();
//         console.log("Fetched data:", data);
//         setMapList(data?.map_list || []);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
  
//     useEffect(() => {
//       if (districtList && districtList.length > 0) {
//         fetchData(districtList[0]); // Fetch data for the first district initially
//       }
//     }, [districtList]);
  
//     const handleChange = (e) => {
//       fetchData(e.target.value);
//     };
  
//     return (
//       <>
//         <div>
//           <select onChange={handleChange}>
//             {districtList?.map((item) => {
//               return (
//                 <option key={item} value={item}>
//                   {item}
//                 </option>
//               );
//             })}
//           </select>
//         </div>
//         <div className="col mt-3">
//           <MapContainer center={center} zoom={8} style={{ height: "50vh", width: "100%" }}>
//             <TileLayer
//               url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
//               attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//             />
//             {/* {mapList.map((item, index) => {
//               if (item.lat && item.lon) {
//                 console.log(`Rendering marker for district: ${item.district}`);
//                 return (
//                   <Marker key={index} position={[item.lat, item.lon]}>
//                     <Popup>
//                       <div>
//                         <strong>District:</strong> {item.district}
//                         <br />
//                         <strong>Value:</strong> {item.value}
//                       </div>
//                     </Popup>
//                   </Marker>
//                 );
//               } else {
//                 console.error(`Invalid marker data: ${item}`);
//                 return null;
//               }
//             })} */}
//                <Marker  position={[27.947147057947525, 79.36523437500001]}>
                  
//                   </Marker>
//           </MapContainer>
//         </div>
//       </>
//     );
//   };
// export default MapContainerData;

import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

// Required to fix marker icons not displaying correctly
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { Table } from "react-bootstrap";
import { Skeleton } from 'antd';
import MarkerClusterGroup from 'react-leaflet-cluster'

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41], 
  popupAnchor: [0, -41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const ResizeMap = () => {
  const map = useMap();

  useEffect(() => {
    const handleResize = () => {
      map.invalidateSize();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [map]);

  return null;
};
const FitBounds = ({ positions }) => {
    const map = useMap();
  
    useEffect(() => {
      if (positions.length) {
        const bounds = L.latLngBounds(positions);
        map.fitBounds(bounds);
      }
    }, [map, positions]);
  
    return null;
  };

const MapContainerData = ({ districtList, details, label, mapList }) => {
    const [center, setCenter] = useState([27.947147057947525, 79.36523437500001]);
    console.log("nyks",mapList)
    return (
      <>
       {mapList?.length>0?
        <div className="col mt-3">
          <MapContainer center={center} zoom={8} style={{ height: "50vh", width: "100%" }}>
            <TileLayer
              url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
                     attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <MarkerClusterGroup
                             chunkedLoading
                         >
             
                {mapList?.map((item, index) => (
            <Marker key={index} position={[item.lat, item.lon]}>
              <Popup>
              
                <Table className="table mb-0" hover>
        <tbody>
          <tr>
            <th>State: </th>
            <td>{item?.state}</td>
          </tr>
          <tr>
            <th>District: </th>
            <td>{item?.district}</td>
          </tr>
          <tr>
          <th> Crop: </th>
          <td>{item?.crop}</td>
        </tr>
        <tr>
        <th> Season: </th>
        <td>{item?.season}</td>
      </tr> 
        <tr>
          <th> Year: </th>
          <td>{item?.year}</td>
        </tr> 
          <tr>
            <th>Level 4: </th>
            <td>{item?.level_4}</td>
          </tr>
          <tr>
            <th>Level 5: </th>
            <td>{item?.level_5}</td>
          </tr> <tr>
            <th>Level 6: </th>
            <td>{item?.level_6}</td>
          </tr>
        
        </tbody>
      </Table>
              </Popup>
            </Marker>
          ))}
          </MarkerClusterGroup>
            <ResizeMap />
            <FitBounds positions={mapList.map(item => [item.lat, item.lon])} />

          </MapContainer>
        </div>
        :<Skeleton className="mt-4"  paragraph={{
            rows: 8,
          }} />}
      </>
    );
  };
  
  export default MapContainerData;