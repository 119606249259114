import React from 'react'
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { SummaryComponent } from './SummaryComponent';
import CCESummary from './CCESummary';

export const Dashboard = () => {
  return (
    <div className='container mt-3'>
      <Tabs
        defaultActiveKey="1"
        // centered
        items={[
          {
            key: '1',
            label: 'CCE',
            children: <CCESummary label="cce" />,
          },
        
          {
            key: '2',
            label: 'CHM',
            children: <CCESummary label="chm"/>,
          }, {
            key: '3',
            label: 'ICV',
            children: <CCESummary  label="icv"/>,
          }, {
            key: '4',
            label: 'ILA',
            children: <CCESummary  label="ila" />,
          },
         
        ]}
      />
    </div>
  );
};
