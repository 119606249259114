import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as d3 from 'd3';
import ApexCharts from 'apexcharts';
import { Col ,Card,CardBody, CardHeader,Row} from 'react-bootstrap';
import MajorStats from './MajorStats';
import { CropStats } from './CropStats';
import { Skeleton } from 'antd';

// Generate gradient colors using d3
const colorScale = d3.scaleSequential(d3.interpolateBlues).domain([0, 7]);

const CCESummary = ({label}) => {
  const [yearStates, setYearStates] = useState({}); 
  const [stateData, setstateData] = useState({}); 
  const [cropStats, setcropStats] = useState({}); 
  const [majorData, setMajorData] = useState({}); 
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://farmconnect.agrani.io/summary_stats?repo=${label}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setYearStates(data?.data?.year_stats); 
        setMajorData(data?.data?.major_stats)
        setcropStats(data?.data?.crop_stats)
        const sortedCropData = Object.entries(data?.data?.state_stats)
        .sort(([, a], [, b]) => b - a)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        setstateData(sortedCropData); 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [label]); // Empty dependency array ensures useEffect runs only once on component mount

  // Render chart when yearStates data changes
 
  const preparePieData = () => {
    const pieData = Object.entries(stateData).map(([state, value]) => ({
      name: state,
      value: value,
    }));
    return pieData;
  };
  const options = {
    title: {
      text: '',
      subtext: '',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      top: 10,
      formatter: function (name) {
        const value = stateData[name] || 0; // Get value for the legend item
        return `${name} - {count|${value}}`;
      },
      textStyle: {
        rich: {
          count: {
            align: 'right',
            color: '#3ba272d9',
            fontWeight: 500,
          },
        },
      },
    },
    grid: {
      containLabel: true,
      left: 2,
      right: 20,
      top: 10,
    },
    
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        center: ['63%', '50%'],
        data: preparePieData(),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
 
  const Yearoption = {
    xAxis: {
      type: 'category',
      data: Object.keys(yearStates)
    },
    yAxis: {
      type: 'value',
      max: Math.max(...Object.values(yearStates)) // Ensure the y-axis max value is set to the max data value
    },
    series: [
      {
        data: Object.values(yearStates),
        type: 'bar',
        itemStyle: {
          color: '#77bef7'
        },
        barWidth: '30%', // Adjust the width of the bars
      }
    ],
    grid: {
      top: "5%",
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}' // Show the category (year) and value on hover
    }
  };
  return (
    
      <Card className='borderColor'><CardBody>
        <Row>
    <Col lg="6">
        <Card className='cardShadow'>
          <span className='bluecolor h6 p-3'>Year-wise Distribution</span>
        <CardBody className='p-1'>
        {Object.keys(yearStates).length > 0 ? (
  <ReactEcharts option={Yearoption} className="mt-2" />
) : (
  <Skeleton
    avatar
    paragraph={{
      rows: 8,
    }}
  />)}

      </CardBody></Card>
    </Col>
    <Col lg="6" className=''>
    <Card className='cardShadow'><span className='h6 p-3 greenSample'>State-wise Distribution</span>
    <CardBody className='p-2'>
    {Object.keys(stateData).length > 0 ? (
    <ReactEcharts option={options}  className='' />
  ) : (
  <Skeleton
    avatar
    paragraph={{
      rows: 8,
    }}
  />)}


  </CardBody>
  </Card>
</Col>
<Col lg="8">
<MajorStats majorStats={majorData} label={label}/>

</Col>
<Col lg="4">
 <CropStats cropStats={cropStats}/>
</Col>
</Row>
</CardBody></Card>
  );
};

export default CCESummary;