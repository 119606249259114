import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Dashboard } from './Dashboard';
function App() {
  return (
    <Router>

        <Routes>
          <Route path="/" element={<Dashboard />} />
        </Routes>
    </Router>
  );
}


export default App;
// import React from "react";
// import {
//   MapContainer,
//   TileLayer,
//   Marker,
//   Popup
// } from "react-leaflet";
// import "leaflet/dist/leaflet.css";

// // Required to fix marker icons not displaying correctly
// import L from "leaflet";
// import markerIcon from "leaflet/dist/images/marker-icon.png";
// import markerShadow from "leaflet/dist/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: markerIcon,
//   shadowUrl: markerShadow,
//   iconAnchor: [12, 41], // adjust these values to properly position the icon
//   popupAnchor: [0, -41]
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const App = () => {
//   const center = [27.947147057947525, 79.36523437500001];
//   const staticMarkers = [
//     { lat: 27.947147057947525, lon: 79.36523437500001, district: "District A", value: "Value A" },
//     { lat: 28.704060, lon: 77.102493, district: "District B", value: "Value B" },
//     { lat: 19.076090, lon: 72.877426, district: "District C", value: "Value C" }
//   ];

//   return (
//     <>
//       <div className="col mt-3">
//         <MapContainer center={center} zoom={6} style={{ height: "80vh", width: "100%" }}>
//           <TileLayer
//             url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//           />
//           {staticMarkers.map((item, index) => (
//             <Marker key={index} position={[item.lat, item.lon]}>
//               <Popup>
//                 <div>
//                   <strong>District:</strong> {item.district}
//                   <br />
//                   <strong>Value:</strong> {item.value}
//                 </div>
//               </Popup>
//             </Marker>
//           ))}
//         </MapContainer>
//       </div>
//     </>
//   );
// };

// export default App;