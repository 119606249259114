import React from 'react'
import {Table} from "react-bootstrap"
import { Col ,Card,CardBody, CardHeader,Row} from 'react-bootstrap';
import { Skeleton } from 'antd';

export const CropStats = ({cropStats}) => {
    const sortedCropData = Object.entries(cropStats)
  .sort(([, a], [, b]) => b - a)
  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

console.log(sortedCropData);
  return (
    <Card className='mt-5 cardShadow'>
    <CardBody >
<div className='greenSample h6 p-3'>Crop-wise Distribution</div>
{Object.keys(sortedCropData).length > 0 ? (
    <div className='backgroundColorBeigeHealth mt-1'>
   <Table className="table mb-0" hover>
      <tbody>
        <tr>
          <td>
            <Table className="table mb-0"  striped>
              <tbody>
                {Object.keys(sortedCropData).map((crop, index) => {
                  return (
                    <tr key={index}>
                      <td>{crop}</td>
                      <td>{sortedCropData[crop]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </td>
        </tr>
      </tbody>
    </Table>


    </div>  ) : (
  <Skeleton
    avatar
    paragraph={{
      rows: 14,
    }}
  />)}
    
    </CardBody>
    </Card>
  )
}
